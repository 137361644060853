import * as ReactDOM from 'react-dom';
import React, { Component } from 'react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _throttle from 'lodash/throttle';
import { loadModules } from 'esri-loader';

let MapEl, MapObserver, locations;

class Map extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      center: this.props.center,
    };
    this.map = null;
  }

  componentDidMount() {
    const { locations } = this.props;
    // console.log('THIS');
    // console.log(locations);
    // console.log('WAS');

    loadModules(
      [
        'esri/Map',
        'esri/views/MapView',
        'esri/Basemap',
        'esri/layers/VectorTileLayer',
        'esri/layers/FeatureLayer',
        'esri/widgets/Popup',
      ],
      {
        css: true,
      }
    ).then(([ArcGISMap, MapView, Basemap, VectorTileLayer, FeatureLayer, Popup]) => {
      const renderer = {
        type: 'simple',
        symbol: {
          type: 'simple-marker',
          style: 'path',
          path: 'M0 0h10L5 16.1z',
          yoffset: 8,
          xoffset: -5,
          color: [9, 4, 70, 1],
        },
      };

      const markers = new FeatureLayer({
        fields: [
          { name: 'im', alias: 'im', type: 'string' },
          { name: 'txt', alias: 'txt', type: 'string' },
          { name: 'id', alias: 'id', type: 'oid' },
          { name: 'url', alias: 'url', type: 'string' },
          { name: 'alt', alias: 'alt', type: 'string' },
        ],
        objectIdField: 'id',
        geometryType: 'point',
        popupEnabled: true,
        source: locations,
        actions: [],
        overwriteActions: true,
        popupTemplate: {
          title: '{txt}',
          content: `<a href="{url}" class="popup__link" data-no-swup><div class="ck-thumb"><div style="display: inline-block; position: relative;"><img src="{im}" alt="{alt}" class="ck-thumb-insert" /><span style="background-image: url({im})" class="ck-thumb-overlay"></span></div></div></a>`,
        },
        renderer: renderer,
      });

      const marker = new FeatureLayer({
        geometryType: 'point',
        objectIdField: 'id',
        popupEnabled: false,
        renderer: renderer,
        source: locations,
      });

      this.map = new ArcGISMap({
        basemap: new Basemap({
          baseLayers: [
            new VectorTileLayer({
              portalItem: {
                id: '031fbbf7079745358a6a13bf6af5cb0d',
              },
            }),
          ],
        }),
        // layers: markers > 1 ? [markers] : [marker],
      });

      this.map.add(locations.length > 1 ? markers : marker);

      // this view
      this.view = new MapView({
        center: this.state.center,
        container: this.mapRef.current,
        map: this.map,
        zoom: 16,
        constraints: {
          maxZoom: 18,
          minZoom: 10,
        },
        highlightOptions: {
          color: '#ff2453',
          haloColor: '#09032c',
          haloOpacity: 0.2,
          fillOpacity: 1,
        },
      });

      this.view.on('mouse-wheel', e => {
        // console.log(e.native);
        window.scrollBy(0, e.deltaY);
        e.stopPropagation();
      });
    });
  }

  componentWillUnmount() {
    if (this.view) this.view.container = null;
  }

  // componentDidUpdate() {
  //   console.group('Map Did Update');
  //   console.log(this.props.slug);
  //   console.log(this.props.locations);

  //   // let subset = slug ? _filter(data, obj => obj.tags.includes(slug)) : data;
  //   console.groupEnd();
  // }

  // renderMarkers = () => {
  //   console.log('render Markers');
  // };

  // recenter = () => {
  //   console.log('recentering');
  // };

  render() {
    // console.log(this.props.slug);
    return <div className="webmap" id="EsriMapEl" ref={this.mapRef} style={{ height: `${this.props.height}` }} />;
  }
}

const redrawMap = () => {
  const BodyEl = document.getElementById('BodyEl');
  const EsriMapEl = document.getElementById('EsriMapEl');
  const MapEl = document.getElementById('MapEl');
  const TopbarEl = document.getElementsByClassName('ck-topbar-side')[0];
  const MapCtaEl = document.getElementsByClassName('ck-map-cta')[0];
  if (MapEl && BodyEl && TopbarEl) {
    const BodyElRect = BodyEl.getBoundingClientRect();
    const TopbarElRect = TopbarEl.getBoundingClientRect();
    const MapCtaRect = MapCtaEl ? MapCtaEl.getBoundingClientRect().height : 0;
    const newHeight = window.innerHeight - (BodyElRect.top + TopbarElRect.top) - MapCtaRect;

    // console.log(window.innerHeight);
    // console.log(BodyElRect.top);
    // console.log(TopbarElRect.top);

    if (EsriMapEl) {
      EsriMapEl.setAttribute('style', `height: ${newHeight}px;`);
    } else {
      MapEl.setAttribute('data-height', `${newHeight}px`);
    }
  }
};

export const initMap = () => {
  redrawMap();
  MapEl = document.getElementById('MapEl');
  if (MapEl) {
    const { height, locationsJson, locationLat, locationLon, slug } = MapEl.dataset;
    if (locationLon && locationLat) {
      const location = {
        geometry: {
          type: 'point',
          x: locationLon,
          y: locationLat,
        },
      };
      ReactDOM.render(<Map locations={[location]} center={[locationLon, locationLat]} height={height} />, MapEl);
    } else {
      fetch(locationsJson).then(response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then(data => {
          const center = ['19.943822612075415', '50.05082834608784'];
          locations = data.map(obj => ({
            geometry: {
              type: 'point',
              x: obj.x,
              y: obj.y,
            },
            attributes: {
              alt: obj.alt,
              id: obj.id,
              im: obj.im,
              // tags: obj.tags,
              txt: obj.txt,
              url: obj.url,
            },
          }));

          ReactDOM.render(<Map locations={locations} center={center} height={height} slug={slug} />, MapEl);
        });
      });
    }
  }
  window.addEventListener('resize', _throttle(redrawMap, 100));

  // look for attributes changes in MapEl
  // if (MapEl) {
  //   MapObserver = new MutationObserver(mutations => {
  //     mutations.forEach(mutation => {
  //       if (mutation.type === 'attributes') initMap();
  //     });
  //   });
  //   MapObserver.observe(MapEl, { attributes: true, childList: false, subtree: false });
  // }
  console.log('✔ Map');
};

export const destroyMap = () => {
  if (MapEl) ReactDOM.unmountComponentAtNode(MapEl);
  window.removeEventListener('resize', _throttle(redrawMap, 100));
  // if (MapObserver) {
  //   MapObserver.disconnect();
  //   MapObserver = undefined;
  // }
  console.log('✖ Map');
};
