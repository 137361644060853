import A11yDialog from 'a11y-dialog';

let Menu, MenuEl, LayoutEl;

export const initMenu = () => {
  console.log('✔ Menu');
  LayoutEl = document.getElementById('LayoutEl');
  MenuEl = document.getElementById('MenuEl');
  if (MenuEl) Menu = new A11yDialog(MenuEl, LayoutEl);
  // if (Menu) {
  //   Menu.on('create', () => {
  //     console.log('Menu Created');
  //   });
  //   Menu.on('destroy', () => {
  //     console.log('Menu Destroyed');
  //   });
  //   Menu.on('show', () => {
  //     console.log('Menu Shown');
  //   });
  //   Menu.on('hide', () => {
  //     console.log('Menu Hidden');
  //   });
  // }
};
export const destroyMenu = () => {
  console.log('✖ Menu');
  if (Menu) Menu.destroy();
};
