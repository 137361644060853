import _ from 'lodash';

const TABLET_BP = 768;
const DESKTOP_BP = 1024;
const HDESKTOP_BP = 1440;

const redrawLayout = () => {
  const WINDOW_WIDTH = window.innerWidth;

  const LayoutEl = document.getElementById('LayoutEl');
  const BodyEl = document.getElementById('BodyEl');
  const TopbarEl = document.getElementsByClassName('ck-topbar-side')[0];

  const layout = LayoutEl.dataset.layout;

  if (layout === 'object') {
    const SideEl = document.getElementById('SideEl');
    const FoldEl = document.getElementById('FoldEl');
    const images = document.getElementsByClassName('ck-insert');

    const BodyElRect = BodyEl.getBoundingClientRect();
    const TopbarElRect = TopbarEl.getBoundingClientRect();
    const newHeight = window.innerHeight - (BodyElRect.top + TopbarElRect.top);

    FoldEl.setAttribute('style', WINDOW_WIDTH >= TABLET_BP ? `min-height: ${newHeight}px;` : '');
    SideEl.setAttribute('style', `height: ${newHeight}px;`);

    if (images) {
      [...images].forEach(image => {
        image.getElementsByClassName('ck-insert-img')[0].setAttribute('style', `max-height: ${newHeight}px;`);
      });
    }
  } else if (layout === 'objects') {
    const SideEl = document.getElementById('SideEl');
    const BodyElRect = BodyEl.getBoundingClientRect();
    const TopbarElRect = TopbarEl.getBoundingClientRect();
    const newHeight = window.innerHeight - (BodyElRect.top + TopbarElRect.top);

    SideEl.setAttribute('style', `height: ${newHeight}px;`);
  } else if (layout === 'home') {
    const slides = document.getElementsByClassName('ck-slider-item');

    const sliderPaginationRect = document.getElementsByClassName('ck-slider-pagination')[0].getBoundingClientRect();
    const topbarRect = document.getElementById('TopbarEl').getBoundingClientRect();

    const newHeight = window.innerHeight - topbarRect.height - sliderPaginationRect.height;

    if (slides) {
      [...slides].forEach(slide => {
        slide.setAttribute('style', `min-height: ${newHeight}px`);
        const insert = slide.getElementsByClassName('ck-insert-img')[0];
        const inner = slide.getElementsByClassName('ck-slide')[0];
        if (insert) {
          insert.setAttribute('style', `max-height: ${newHeight}px`);
        }
        if (inner) {
          inner.setAttribute('style', `min-height: ${newHeight}px`);
        }
      });
    }
  }
  return null;
};

export const initLayout = () => {
  console.log('✔ Layout');
  window.addEventListener('resize', redrawLayout);
  redrawLayout();
  // setTimeout(redrawLayout, 300);
};

export const destroyLayout = () => {
  console.log('✖ Layout');
  window.removeEventListener('resize', redrawLayout);
};
