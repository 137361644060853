let Hashlinks;

const scrollto = (e, el) => {
  e.preventDefault();
  document.querySelector(el).scrollIntoView({ behavior: 'smooth' });
};

export const initHashlinks = () => {
  console.log('✔ Hashlinks');
  Hashlinks = document.querySelectorAll('a[href^="#"]');
  Hashlinks.forEach(link => {
    link.addEventListener('click', e => scrollto(e, link.getAttribute('href')));
  });
};

export const destroyHashlinks = () => {
  console.log('✖ Hashlinks');
  Hashlinks.forEach(link => {
    link.removeEventListener('click', e => scrollto(e, link.getAttribute('href')));
  });
  Hashlinks = undefined;
};
