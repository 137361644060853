// import Swup from 'swup';
// import SwupPreloadPlugin from '@swup/preload-plugin';
// import SwupScrollPlugin from '@swup/scroll-plugin';

import './app.scss';
import { initAccordion, destroyAccordion } from './scripts/accordion';
import { initMenu, destroyMenu } from './scripts/menu';
import { initHashlinks, destroyHashlinks } from './scripts/hashlinks';
import { initLayout, destroyLayout } from './scripts/layout';
import { initMap, destroyMap } from './scripts/map';
import { initSlider, destroySlider } from './scripts/slider';
import { initTooltips, destroyTooltips } from './scripts/tooltips';
import { initTags, destroyTags } from './scripts/tags';

// let SwupLoader;

document.documentElement.className = document.documentElement.className.replace('no-js', 'js');

const init = () => {
  console.group('Init:');

  initMap();
  initMenu();
  initHashlinks();
  initSlider();
  initAccordion();
  initTooltips();
  initTags();
  initLayout(); // must come in last

  console.groupEnd();
};

const destroy = () => {
  console.group('Destroy');

  destroyLayout(); // must come in first
  destroyMap();
  destroyMenu();
  destroyHashlinks();
  destroySlider();
  destroyAccordion();
  destroyTooltips();
  destroyTags();

  console.groupEnd();
};

// SwupLoader = new Swup({
//   containers: ['#LayoutEl', '#AppendsEl'],
//   linkSelector:
//     'a[href^="' +
//     window.location.origin +
//     '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
//   plugins: [
//     new SwupPreloadPlugin(),
//     new SwupScrollPlugin({
//       doScrollingRightAway: false,
//       animateScroll: false,
//       scrollFriction: 0.3,
//       scrollAcceleration: 0.04,
//     }),
//   ],
// });

init();

// SwupLoader.on('contentReplaced', init);
// SwupLoader.on('willReplaceContent', destroy);
