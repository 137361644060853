import { tns } from 'tiny-slider/src/tiny-slider';

let Slider, SliderEl;

export const initSlider = () => {
  console.log('✔ Slider');
  SliderEl = document.getElementById('SliderEl');
  if (SliderEl) {
    Slider = tns({
      // autoplay: true,
      animateIn: 'tns-fadeIn',
      animateOut: 'tns-fadeOut',
      arrowKeys: true,
      autoplayButtonOutput: false,
      autoplayHoverPause: true,
      autoplayResetOnVisibility: true,
      autoplayTimeout: 5000,
      container: '.ck-slider-body',
      controls: false,
      edgePadding: 0,
      gutter: 10,
      items: 1,
      loop: true,
      mouseDrag: true,
      navAsThumbnails: true,
      navContainer: '.ck-slider-pages',
      navPosition: 'bottom',
      preventScrollOnTouch: false,
      slideBy: 'page',
      speed: 500,
      touch: true,
    });
    Slider.events.on('indexChanged', () => {
      const info = Slider.getInfo();
      const index = info.index;
      const pageLinks = document.querySelectorAll('.ck-slider-page');
      [...pageLinks].forEach((item, i) => {
        if (i < index) {
          item.classList.add('tns-nav-active');
        } else {
          item.classList.remove('tns-nav-active');
        }
      });
    });
    setTimeout(() => Slider.goTo('next'), 10000);
  }
};

export const destroySlider = () => {
  console.log('✖ Slider');
  if (SliderEl) Slider.destroy();
};
