import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export const initTooltips = () => {
  console.log('✔ Tooltips');
  tippy('[data-tippy-content]');
};
export const destroyTooltips = () => {
  console.log('✖ Tooltips');
};
