import A11yDialog from 'a11y-dialog';

let LayoutEl, MapEl, Tags, TagsDialog, TagsEl;

const updateMapSlug = slug => {
  if (MapEl) MapEl.setAttribute('data-slug', slug);
};

export const initTags = () => {
  // get dom elements
  LayoutEl = document.getElementById('LayoutEl');
  MapEl = document.getElementById('MapEl');
  TagsEl = document.getElementById('TagsEl');

  // mount drawer
  if (TagsEl) TagsDialog = new A11yDialog(TagsEl, LayoutEl);

  // wire tag clicks
  Tags = document.querySelectorAll('.ck-tag-link');
  // if (Tags) Tags.forEach(Tag => Tag.addEventListener('click', () => updateMapSlug(Tag.dataset.tagSlug)));

  const $tagInput = document.querySelector('#tagInput');

  const typeHandler = function(e) {
    if (Tags) {
      Tags.forEach(tag => {
        if (tag.innerText.includes(e.target.value)) {
          tag.parentElement.setAttribute('style', 'display: block');
        } else {
          tag.parentElement.setAttribute('style', 'display: none');
        }
      });
    }
  };

  if ($tagInput) {
    $tagInput.addEventListener('input', typeHandler);
  }

  console.log('✔ Tags');
};

export const destroyTags = () => {
  // remove event listeners
  // if (Tags) Tags.forEach(Tag => Tag.removeEventListener('click', () => updateMapSlug(Tag.dataset.tagSlug)));

  // unmount drawer
  if (TagsDialog) TagsDialog.destroy();

  // clear variables
  LayoutEl = undefined;
  MapEl = undefined;
  Tags = undefined;
  TagsDialog = undefined;
  TagsEl = undefined;

  console.log('✖ Tags');
};
