let Accordions, AccordionItems;

const toggleAccordionItem = el => {
  const headEl = el.parentNode;
  const bodyEl = headEl.nextElementSibling;

  const wasOpen = el.getAttribute('data-accordion-active');

  if (wasOpen === 'false') {
    bodyEl.classList.add('ck-accordion-body--open');
    bodyEl.setAttribute('aria-hidden', false);
    el.setAttribute('aria-expanded', true);
    el.setAttribute('data-accordion-active', true);
    headEl.classList.add('ck-accordion-head--open');
  } else {
    bodyEl.classList.remove('ck-accordion-body--open');
    bodyEl.setAttribute('aria-hidden', true);
    el.setAttribute('aria-expanded', false);
    el.setAttribute('data-accordion-active', false);
    headEl.classList.remove('ck-accordion-head--open');
  }
};

export const initAccordion = () => {
  console.log('✔ Accordion');

  // mount accordions
  Accordions = document.querySelectorAll('.ck-accordion');
  if (Accordions) Accordions.forEach(accordion => accordion.classList.add('ck-accordion-mounted'));

  // mount accordion items
  AccordionItems = document.querySelectorAll('.ck-accordion-trigger');
  if (AccordionItems) {
    // add event listeners
    AccordionItems.forEach((item, i) => {
      item.addEventListener('click', () => toggleAccordionItem(item));
    });
  }
};

export const destroyAccordion = () => {
  console.log('✖ Accordion');
  // unmount accordions
  if (Accordions) Accordions.forEach(accordion => accordion.classList.remove('ck-accordion-mounted'));
  Accordions = undefined;

  // unmount accordion items
  if (AccordionItems)
    AccordionItems.forEach(item => {
      item.removeEventListener('click', () => toggleAccordionItem(item));
    });
  AccordionItems = undefined;
};
